import React, { createContext, useCallback, useState } from 'react';
import Toast, { ToastProps } from 'shared/toast';

type ToastOptions = Omit<ToastProps, 'text'>;
export type ToastOpen = (text: string, options?: ToastOptions) => void;
type ToastContext = Readonly<[ToastOpen]>;

const initialContext: ToastContext = [() => undefined];
const ToastContext = createContext<ToastContext>(initialContext);

const ToastProvider: React.FC = (props) => {
  const [state, setState] = useState<ToastProps & { isOpen: boolean }>({
    isOpen: false,
    text: '',
  });
  const openToast = useCallback((text: string, options: ToastOptions = {}) => {
    setState({
      isOpen: true,
      text,
      ...options,
    });
  }, []);
  const contextValue = [openToast] as const;

  const { isOpen, onDismiss, ...toastProps } = state;
  const internalOnDismiss = () => {
    setState({ isOpen: false, text: '' });
    if (onDismiss) {
      onDismiss();
    }
  };

  const { children } = props;
  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {isOpen && <Toast onDismiss={internalOnDismiss} {...toastProps} />}
    </ToastContext.Provider>
  );
};

export { ToastContext };
export default ToastProvider;
